footer {
  position: fixed;
  bottom: 2%;
  width: 100%;
}

.App {
  text-align: center;
}

.mainContent {
  padding: 10px 20px;
}

.navbarStyle {
  padding: 0.5rem 1rem !important;
}

.card-header {
  background-color: #004a8d;
  color: #dee4f1;
}

.dropdown-button,
.dropdown-button:focus,
.dropdown-button:active {
  border-width: 0px;
  border-color: transparent;
}

.btn-outline-success,
.btn-outline-success.disabled {
  color: #004a8d !important;
  border-color: #004a8d !important;
  outline-color: #004a8d !important;
}

.btn-outline-success:active,
.btn-outline-success:hover {
  color: #dee4f1 !important;
  background-color: #004a8d !important;
  border-color: #004a8d !important;
  outline-color: #004a8d !important;
}

.btn-outline-success:focus,
.btn-outline-success:active:focus {
  box-shadow: 0 0 0 0.25rem rgb(222 228 241 / 90%);
}

.navbar-container {
  background-color: #ffffff !important;
}

div.navbar-collapse {
  margin-top: 1rem !important;
}

.large {
  font-size: 1.4em;
}

p {
  margin: 0;
  padding: 0;
}

.ag-theme-alpine .ag-header {
  background-color: #ffffff;
}

.bm-cross-button {
  right: 22px !important;
}

@media (min-width: 992px) {
  div.navbar-collapse {
    margin-top: 0rem !important;
  }
}

@media (max-width: 992px) {
  #navYear {
    margin-left: 0px !important;
  }
}

td, th {
  text-align: left !important;
  width:0.1%;
  white-space: nowrap;  
}

td.left, th.left {
  text-align: left !important;
}

td.right, th.right {
  text-align: right !important;
}

td.center, th.center {
  text-align: center !important;
}

.breadcrumb-item {
  font-size: 20px;
}

.progress {
  --bs-progress-height: 0.35rem !important;
  height: 0.35rem !important;
}

label.disabled {
  background-color: gray;
  border: #555555;
  cursor: not-allowed;
}

.spinner-border {
  margin-left: auto;
  margin-right: auto;
  width: 80px !important;
  height: 80px !important;
}
